import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from './utils/axiosInstance';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import Sidebar from './components/Sidebar';
import TopNavbar from './components/TopNavbar';
import Element from './components/Element';
import ElementDetail from './components/ElementDetail';
import CompoundList from './components/CompoundList';
import Arrow from './components/Arrow';
import CompoundDetail from './components/CompoundDetail';
import Atomic from './components/Atomics';
import BottomBar from './components/BottomBar';

function App() {
  const [elements, setElements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(() => {
    const savedState = localStorage.getItem('isSidebarOpen');
    return savedState ? JSON.parse(savedState) : true;
  });
  const [activeElement, setActiveElement] = useState(null);
  const [grouping, setGrouping] = useState('category');
  const [showMetal, setShowMetal] = useState(false);
  const [showNonMetal, setShowNonMetal] = useState(false);
  const [showRad, setShowRad] = useState(false);
  const [showElnev, setShowElnev] = useState(false);
  const [showION, setShowION] = useState(false);
  const [showORB, setShowORB] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [nonMetalColor, setNonMetalColor] = useState('rgba(255, 123, 156, 0.7)');
  const [metalColor, setMetalColor] = useState('rgba(107, 203, 119, 0.7)');
  const [electronegativityColor, setElectronegativityColor] = useState('rgba(83, 162, 190, 0.7)');
  const [ionizationColor, setIonizationColor] = useState('rgba(216, 140, 0, 0.7)');
  const [radiusColor, setRadiusColor] = useState('rgba(161, 127, 224, 0.7)');

  const tableRef = useRef(null);
  const elementRef = useRef();

  useEffect(() => {
    axiosInstance
      .get('elements/')
      .then((response) => {
        setElements(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('There was an error fetching the elements:', error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (elementRef.current && !elementRef.current.contains(event.target)) {
        setActiveElement(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleMetal = () => {
    setShowMetal((prev) => !prev);
    setShowNonMetal(false);
    setShowRad(false);
    setShowElnev(false);
    setShowION(false);
  };

  const toggleNonMetal = () => {
    setShowNonMetal((prev) => !prev);
    setShowMetal(false);
    setShowRad(false);
    setShowElnev(false);
    setShowION(false);
  };

  const toggleRad = () => {
    setShowRad((prev) => !prev);
    setShowMetal(false);
    setShowNonMetal(false);
    setShowElnev(false);
    setShowION(false);
  };

  const toggleElenv = () => {
    setShowElnev((prev) => !prev);
    setShowMetal(false);
    setShowRad(false);
    setShowNonMetal(false);
    setShowION(false);
  };

  const toggleION = () => {
    setShowION((prev) => !prev);
    setShowMetal(false);
    setShowRad(false);
    setShowElnev(false);
    setShowNonMetal(false);
  };

  const toggleORB = () => {
    setShowORB((prev) => !prev);
  };

  const groupingOptions = {
    category: {
      colors: {
        1: '#FF6F61',
        2: '#FFD700',
        4: '#FF4500',
        5: '#00BFFF',
        6: '#DA70D6',
        7: '#808080',
        8: '#2F4F4F',
        9: '#4682B4',
        10: '#9932CC',
        11: '#FF1493',
        12: '#A9A9A9',
      },
      labels: {
        1: 'Alkali Metals',
        2: 'Alkaline Earth Metals',
        4: 'Post-transition Metals',
        5: 'Metalloids',
        6: 'Other Reactive Nonmetals',
        7: 'Halogens',
        8: 'Noble Gases',
        9: 'Transition Metals',
        10: 'Lanthanides',
        11: 'Actinides',
        12: 'Unknown Properties',
      },
    },
    orbitalBlock: {
      colors: {
        s: '#1E90FF',
        p: '#FF7F50',
        d: '#6A5ACD',
        f: '#20B2AA',
      },
      labels: {
        s: 's-block',
        p: 'p-block',
        d: 'd-block',
        f: 'f-block',
      },
    },
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => {
      const newState = !prev;
      localStorage.setItem('isSidebarOpen', JSON.stringify(newState));
      return newState;
    });
  };

  const handleGroupingChange = () => {
    const groupingKeys = Object.keys(groupingOptions);
    const currentIndex = groupingKeys.indexOf(grouping);
    const nextGrouping = groupingKeys[(currentIndex + 1) % groupingKeys.length];
    setGrouping(nextGrouping);
  };

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderElement = (element) => {
    const { colors } = groupingOptions[grouping];
    const colorKey = grouping === 'category' ? element.group : element.orbital_block;

    return (
      <div
        className="element-card"
        key={element.atomic_number}
        style={{
          backgroundColor: colors[colorKey] || '#CCCCCC',
          gridRowStart: element.position_y,
          gridColumnStart: element.position_x,
        }}
      >
        <Link to={`/elements/${element.atomic_number}`} className="element-link">
          <div className="element-symbol">{element.symbol}</div>
          <div className="element-name">{element.name}</div>
          <div className="element-atomic-number">#{element.atomic_number}</div>
        </Link>
      </div>
    );
  };

  const renderLegend = () => {
    const { colors, labels } = groupingOptions[grouping];

    return (
      <div className="legend-in-grid">
        <div className="legend-header">
          <button onClick={handleGroupingChange} className="grouping-toggle">
            Orbital Blocks
          </button>
          <button
            onClick={toggleNonMetal}
            className="ionization-energy-toggle"
            style={{ color: nonMetalColor }}
          >
            Non-Metallic Behaviour Trend
          </button>
          <button
            onClick={toggleMetal}
            className="ionization-energy-toggle"
            style={{ color: metalColor }}
          >
            Metallic Behaviour Trend
          </button>
          <button
            onClick={toggleElenv}
            className="ionization-energy-toggle"
            style={{ color: electronegativityColor }}
          >
            Electron Affinity Trend
          </button>
          <button
            onClick={toggleION}
            className="ionization-energy-toggle"
            style={{ color: ionizationColor }}
          >
            Ionization Energy Trend
          </button>
          <button
            onClick={toggleRad}
            className="ionization-energy-toggle"
            style={{ color: radiusColor }}
          >
            Radius Trend
          </button>
        </div>
        <p>Click on the elements to see detailed information about them</p>
        <div className="legend-container">
          {Object.entries(colors).map(([key, color]) => (
            <div key={key} className="legend-item">
              <div
                className="legend-color-circle"
                style={{ backgroundColor: color }}
              ></div>
              <span className="legend-label">{labels[key]}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };
  const[navsize,setnavsize]=useState('140px')
  return (
    <Router>
      <div className={`App`}>
        <TopNavbar />
        <button onClick={toggleSidebar} className="sidebar-toggle">
          <div className={`hamburger-icon ${isSidebarOpen ? 'open' : ''}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
        {isSidebarOpen && <Sidebar />}
        <div style={{   
          flexGrow: 1,
          marginBottom: 0,
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          
          //backgroundColor: 'rgba(198, 128, 18, 0.1)',
          }}>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  {loading ? (
                    <div className="loader"></div>
                  ) : (
                    <div
                      className="periodic-table-wrapper"
                      ref={tableRef}
                      style={{
                        marginTop: '100px',
                        
                        //backgroundColor: 'rgba(0, 128, 0, 0.1)',
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: isSidebarOpen 
                        ? `${Math.max((windowWidth - (1880 * Math.min((windowWidth + 10 - 145) / 1880, (windowHeight - 100) / 950))) / 2, 0) +80}px`
                        : `${Math.max((windowWidth - (1880 * Math.min((windowWidth + 10) / 1880, (windowHeight - 100) / 950))) / 2, 0)}px`,                  
                        marginRight: isSidebarOpen 
                        ? `${Math.max((windowWidth - (1880 * Math.min((windowWidth + 10 - 145) / 1880, (windowHeight - 100) / 950))) / 2, 0)}px`
                        : `${Math.max((windowWidth - (1880 * Math.min((windowWidth + 10) / 1880, (windowHeight - 100) / 950))) / 2, 0)}px`,

                        transform: isSidebarOpen
                        ? `scale(${Math.min((windowWidth + 10 - 145) / 1880, (windowHeight - 100) / 950)})`
                        : `scale(${Math.min((windowWidth + 10) / 1880, (windowHeight - 100) / 950)})`,
                        transformOrigin: 'top left',
                        maxHeight: `${Math.min((windowWidth + 10 - (isSidebarOpen ? 145 : 0)) / 1880, (windowHeight - 100) / 950) * 950
                        }px`, // Force height limit
                        marginBottom: 0,
                        padding: 0,
                        
                      }}
                    >
                      <div className="periodic-table">
                        {renderLegend()}
                        {elements.map((element) => renderElement(element))}
                      </div>
                      {showNonMetal && (
                        <Arrow x1={2} y1={8} x2={17} y2={3} tableRef={tableRef} color={nonMetalColor} />
                      )}
                      {showMetal && (
                        <Arrow x1={17} y1={3} x2={2} y2={8} tableRef={tableRef} color={metalColor} />
                      )}
                      {showElnev && (
                        <Arrow x1={2} y1={2} x2={17} y2={2} tableRef={tableRef} color={electronegativityColor} />
                      )}
                      {showElnev && (
                        <Arrow x1={17} y1={9} x2={17} y2={2} tableRef={tableRef} color={electronegativityColor} />
                      )}
                      {showION && (
                        <Arrow x1={2} y1={2} x2={17} y2={2} tableRef={tableRef} color={ionizationColor} />
                      )}
                      {showION && (
                        <Arrow x1={17} y1={9} x2={17} y2={2} tableRef={tableRef} color={ionizationColor} />
                      )}
                      {showRad && (
                        <Arrow x1={2} y1={2} x2={2} y2={9} tableRef={tableRef} color={radiusColor} />
                      )}
                      {showRad && (
                        <Arrow x1={18} y1={9} x2={2} y2={9} tableRef={tableRef} color={radiusColor} />
                      )}
                    </div>
                  )}
                  
                </>
              }
            />
  <Route
    path="/elements/:atomicNumber"
    element={
      <div
        style={{
          marginLeft: isSidebarOpen ? navsize : '0',
          transition: 'margin-left 0.3s ease',
        }}
      >
        <ElementDetail />
      </div>
    }
  />
  <Route
    path="/compounds/:compoundname"
    element={
      <div
        style={{
          marginLeft: isSidebarOpen ? navsize : '0',
          transition: 'margin-left 0.3s ease',
        }}
      >
        <CompoundDetail />
      </div>
    }
  />
  <Route
    path="/Compounds/"
    element={
      <div
        style={{
          marginLeft: isSidebarOpen ? navsize : '0',
          transition: 'margin-left 0.3s ease',
        }}
      >
        <CompoundList />
      </div>
    }
  />
  <Route
    path="/AtomicTheories/"
    element={
      <div
        style={{
          marginLeft: isSidebarOpen ? navsize : '0',
          transition: 'margin-left 0.3s ease',
        }}
      >
        <Atomic isSidebarOpen={isSidebarOpen} />
      </div>
    }
  />         </Routes>
        </div>
        <BottomBar />
      </div>
    </Router>
  );
}

export default App;
