import React, { useEffect, useState } from 'react';
import axios from '../utils/axiosInstance';
import './Atomics.css'; // Import the CSS file
import "../App.css";

const Atomic = () => {
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('content/'); // Endpoint matching DRF router setup
        setContents(response.data); // Expecting an array of content objects
        setLoading(false)
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false)
      }
    };

    fetchData();
  }, []);
  if (loading) return <div className="loader"></div>;
  return (
    <div className="atomic-container">
      <h1 className="atomic-title"> </h1>
      <ul className="atomic-list">
        {contents.map((content) => (
          <li key={content.id} className="atomic-list-item">
    
            {content.image && (
              <img
                src={content.image}
                alt={`Image for ${content.name}`}
                className="atomic-image"
              />
            )}
            <p className="atomic-description">created by Dahlia Aghaei</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Atomic;
