import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance'; // Use the same Axios instance as App.js
import { Link } from 'react-router-dom';
import './CompoundList.css'; // Custom styles for this component
import "../App.css";

function CompoundList() {
    const [compounds, setCompounds] = useState([]);
    const [filteredCompounds, setFilteredCompounds] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    
    // Fetch compounds and categories from the API
    useEffect(() => {
        const fetchCompounds = async () => {
            try {
                const response = await axiosInstance.get('/compounds/'); // Correct the API endpoint
                setCompounds(response.data);
                setFilteredCompounds(response.data); // Initially display all compounds
                setLoading(false);
            } catch (error) {
                console.error("Error fetching compounds:", error);
                setLoading(false);
            }
        };

        const fetchCategories = async () => {
            try {
                const response = await axiosInstance.get('/compoundscat/'); // Fetch categories from API
                setCategories(['All', ...response.data.map((cat) => ({
                    id: cat.id,
                    name: cat.name
                }))]);
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        fetchCompounds();
        fetchCategories();
    }, []);

    // Filter compounds based on the selected category
    useEffect(() => {
        if (selectedCategory === 'All') {
            setFilteredCompounds(compounds);
        } else {
            setFilteredCompounds(
                compounds.filter((compound) =>
                    compound.categories.includes(
                        categories.find((cat) => cat.name === selectedCategory)?.id
                    )
                )
            );
        }
    }, [selectedCategory, compounds, categories]);


    if (loading) return <div className="loader"></div>;
    if (!compounds) return <div className="error-message">Compound not found.</div>;


    return (
        <div className={'compound-page'}>
            <div className="top-bar">
                <label htmlFor="category-select" className="category-label">
                    Filter by Category:
                </label>
                <select
                    id="category-select"
                    className="category-select"
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                >
                    {categories.map((category) => (
                        <option key={category.id || category} value={category.name || category}>
                            {category.name || category}
                        </option>
                    ))}
                </select>
            </div>
            <div className="compounds-container">
                {filteredCompounds.map((compound) => (
                    <Link
                        to={`/compounds/${compound.name}`}
                        key={compound.id} // Set key here to avoid React warning
                        className="compound-card-link" // Add a class for styling the Link
                    >
                        <div className="compound-card">
                            {/* Render image if lewis_structure_image exists */}
                            {compound.lewis_structure_image && (
                                <img
                                    src={compound.lewis_structure_image}
                                    alt={`Lewis structure of ${compound.name}`}
                                    className="compound-image"
                                />
                            )}
                            <h3 className="compound-name">{compound.formula}</h3>
                            <p className="compound-formula">{compound.name}</p>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
}

export default CompoundList;
